import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide21/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide21/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide21/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide21/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide21/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo llamar a un cliente para ofrecer un producto? 7 pasos a seguir
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Una llamada efectiva puede abrir puertas y generar oportunidades de
      negocio. Sin embargo, es importante abordar esta tarea con una cuidadosa
      planificación y una comunicación persuasiva.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    El primer paso para una llamada exitosa es establecer una conexión sólida
    desde el inicio. Es fundamental captar el interés del cliente a través de un
    saludo amigable y una presentación clara y concisa.
    <br /> <br />
    Es importante ser respetuoso y escuchar activamente al cliente. Permitir que
    el cliente se exprese, plantee preguntas y dudas, demuestra interés y
    profesionalismo.
  </p>
)

const data = {
  start: {
    support: "Guía | Televentas",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Evitar el uso de scripts rígidos para ser más naturales.",
      },
      {
        key: 2,
        text: "Enfoque en el bienestar de los clientes potenciales.",
      },
      {
        key: 3,
        text: "Cómo enfocarnos en las emociones de los clientes.",
      },
      {
        key: 4,
        text: "Técnicas del fear of missing out (FOMO).",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
